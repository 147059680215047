<template>
  <div id="editUserForm" class="editUserForm__wrap">
    <CCard>
      <CCardBody @change="isFormChange = true">
        <div class="back-to-operator">
          <router-link to="/admin/user-management">
            <CIcon name="cil-arrow-left" class="mr-1" />
            {{ $t('EDIT_USER_COMPONENT_BUTTON_BACK_TO_OPERATOR') }}
          </router-link>
        </div>
        <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <CForm @submit.prevent="handleSubmit(onSubmit)">
            <ValidationProvider name="EDIT_USER_FORM_COMPONENT_FIELD_SELECT_ROLE" rules="required">
              <div slot-scope="{ errors }">
                <CSelect
                  :label="$t('EDIT_USER_FORM_COMPONENT_LABEL_SELECT_ROLE')"
                  horizontal
                  :value.sync="role"
                  :placeholder="$t('EDIT_USER_FORM_COMPONENT_TEXT_PLACEHOLDER_SELECT')"
                  type="number"
                  :options="options"
                />
                <CRow v-if="errors[0]">
                  <CCol :sm="{ offset: '3' }"></CCol>
                  <CCol :sm="9">
                    <span class="error-msg mt-1">{{ errors[0] }}</span>
                  </CCol>
                </CRow>
              </div>
            </ValidationProvider>

            <ValidationProvider name="EDIT_USER_FORM_COMPONENT_FIELD_NAME" rules="required">
              <div slot-scope="{ errors }">
                <CInput
                  :label="$t('EDIT_USER_FORM_COMPONENT_LABEL_NAME')"
                  :placeholder="isCreate ? $t('EDIT_USER_FORM_COMPONENT_TEXT_PLACEHOLDER_NAME') : user.name"
                  v-model="name"
                  horizontal
                  class="form-field-name"
                ></CInput>
                <CRow v-if="errors[0]">
                  <CCol :sm="{ offset: '3' }"></CCol>
                  <CCol :sm="9">
                    <span class="error-msg mt-1">{{ errors[0] }}</span>
                  </CCol>
                </CRow>
              </div>
            </ValidationProvider>

            <ValidationProvider name="EDIT_USER_FORM_COMPONENT_FIELD_EMAIL" rules="">
              <div slot-scope="{ errors }">
                <div role="group" class="form-field-name form-group form-row">
                  <label class="col-form-label col-sm-3">{{ $t('EDIT_USER_FORM_COMPONENT_COUNTRY_LABEL') }}:</label>
                  <div class="col-sm-9">
                    <select v-model="country_code" class="form-control">
                      <option value>{{ $t('EDIT_USER_FORM_COMPONENT_CHOOSE_COUNTRY_LABEL') }}</option>
                      <option v-for="item in countries" :key="item.value" :value="item.value">{{ item.label }}</option>
                    </select>
                    <span class="error-msg-wrap" v-if="errors[0]">
                      <span class="error-msg">{{ errors[0] }}</span>
                    </span>
                  </div>
                </div>
              </div>
            </ValidationProvider>

            <div role="group" class="form-field-name form-group form-row" v-if="isEdit">
              <label class="col-form-label col-sm-3">{{ $t('EDIT_USER_FORM_COMPONENT_LABEL_EMAIL') }}:</label>
              <div class="col-sm-9">
                <strong>{{ user.email }}</strong>
              </div>
            </div>

            <div role="group" class="form-field-name form-group form-row" v-if="isEdit">
              <label class="col-form-label col-sm-3">{{ $t('EDIT_USER_FORM_COMPONENT_LABEL_STATUS') }}:</label>
              <div class="col-sm-9">
                <div class="edit-email-name">
                  <label class="btn-switch">
                    <input type="checkbox" class="checkbox" v-model="isActive" />
                    <span class="check-silder"></span>
                  </label>
                </div>
              </div>
            </div>

            <ValidationProvider name="EDIT_USER_FORM_COMPONENT_FIELD_EMAIL" rules="required|email" v-if="isCreate">
              <div slot-scope="{ errors }">
                <CInput
                  :label="$t('EDIT_USER_FORM_COMPONENT_LABEL_EMAIL')"
                  :placeholder="$t('EDIT_USER_FORM_COMPONENT_TEXT_PLACEHOLDER_EMAIL')"
                  horizontal
                  v-model="email"
                  class="form-field-name"
                />
                <CRow v-if="errors[0]">
                  <CCol :sm="{ offset: '3' }"></CCol>
                  <CCol :sm="9">
                    <span class="error-msg mt-1">{{ errors[0] }}</span>
                  </CCol>
                </CRow>
              </div>
            </ValidationProvider>


          </CForm>
        </ValidationObserver>

        <div class="form-actions">
          <button
            v-if="isEdit"
            @click="reSendActiveCode(user)"
            :class="user.user_sts == 'ACTIVATED' ? 'mr-2 text-white btn btn-info disabled' : 'mr-2 text-white btn btn-info'"
            :disabled="user.user_sts == 'UNACTIVATED' ? false : true"
          >
            {{ $t('EDIT_USER_FORM_COMPONENT_BUTTON_RESEND_ACTIVATE_CODE') }}
          </button>
          <CButton v-if="isCreate" @click="openWarningLeaveModal" class="btn-save--cancel">{{
            $t('EDIT_USER_FORM_COMPONENT_BUTTON_CANCEL')
          }}</CButton>
          <CButton @click="onSubmit" color="primary" class="btn-save--create">{{
            isCreate ? $t('EDIT_USER_FORM_COMPONENT_BUTTON_CREATE') : $t('EDIT_USER_FORM_COMPONENT_BUTTON_SAVE')
          }}</CButton>
        </div>
      </CCardBody>
    </CCard>

    <StakingModal :linkTo="linkTo" :warningLeavePage="warningLeavePage" @close-modal="closeModal" />
  </div>
</template>

<script>
import { endpoints, RoleType } from '@/constants';
import { mapState, mapActions } from 'vuex';
import StakingModal from '@/components/StakingModal/index';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'EditUserFormComponent',
  components: { StakingModal },
  data() {
    return {
      status: '',
      email: '',
      name: '',
      role: null,
      country_code: '',
      isActive: false,
      isFormChange: false,
      warningLeavePage: false,
      options: [],
      countries: [],
      isEdit: false,
      isCreate: false,
      linkTo: '/admin/user-management',
    };
  },
  computed: mapState({
    user: (state) => state.user.userSelected,
    action: (state) => state.user.action,
    currentRole: (state) => state.auth.role,
  }),

  async mounted() {
    await Promise.all([this.getRoles(), this.getOperatorCountries()]);

    if (this.action == 'edit') {
      this.isEdit = true;

      const userDetails = await this.getUserDetails(this.user);
      this.name = userDetails.name;
      this.status = userDetails.user_sts;
      this.role = userDetails.role_id;
      this.country_code = userDetails.country_code || '';
      this.isActive = userDetails.user_sts === 'ACTIVATED';
    } else {
      this.isCreate = true;
    }
  },
  methods: {
    ...mapActions(['getSelectedUser', 'updateUser', 'createUser', 'reSendActiveCode']),
    async getRoles() {
      const result = await this.$http.get(endpoints.getRolesForCurrentUser);
      if (result) {
        this.options = result.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      }
    },
    async getOperatorCountries() {
      try {
        const result = await this.$http.get(endpoints.getOperatorCountries);
        this.countries = result.data.map((item) => {
          return {
            value: item.code,
            label: item.name,
          };
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('EDIT_USER_FORM_COMPONENT_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getUserDetails(user) {
      const result = await this.$http.get(endpoints.getUser(user.id));

      return result.data;
    },
    async onSubmit() {
      this.$refs.form.validate().then((isValid) => {
        if (!isValid) {
          return;
        }

        if (this.isCreate) {
          this.create();
        } else {
          this.update();
        }
      });
    },
    async update() {
      const updateUser = {
        id: this.user.id,
        name: this.name,
        email: this.user.email,
        role_id: this.role,
        country_code: this.country_code || null,
        status: this.isActive ? 'ACTIVATED' : 'UNACTIVATED',
      };

      await this.updateUser(updateUser);
    },
    async create() {
      const newUser = {
        email: this.email,
        name: this.name,
        role_id: this.role,
        country_code: this.country_code || null,
      };

      await this.createUser(newUser);
    },
    openWarningLeaveModal() {
      if (this.isFormChange) {
        this.warningLeavePage = true;
      } else {
        this.$router.push(this.linkTo);
      }
    },
    closeModal(val) {
      this.warningLeavePage = val;
    },
  },
};
</script>

<style lang="scss">
#editUserForm {
  .custom-header {
    background: #0f85ea;
    padding-top: 5px;
    padding-bottom: 0;
    color: #fff;
  }

  .switch {
    padding-left: 10px;

    @media (max-width: 1100px) {
      margin-top: 10px;
    }
  }

  .btn-custom-link {
    outline: none;
    min-width: 120px;
  }

  .btn-selected {
    outline: none;
    min-width: 120px;
    background: #4267db;
    color: #eeeeee;
  }

  .status-wrap {
    button {
      margin-left: 20px;
    }
  }

  .email-wrap {
    display: block;
  }

  .error-msg {
    color: red;
  }

  .disabled {
    cursor: not-allowed;
  }

  .role-select {
    width: 100%;

    option {
      width: 100%;
    }
  }

  .card-body {
    .form-group {
      label {
        font-size: 14px;
        font-weight: 300;
        line-height: 32px;
        color: #3e4b65;
      }
      select,
      input {
        font-size: 16px;
        font-weight: 500;
        color: #3a4a7f;
        &::placeholder {
          font-weight: 400;
        }
      }
    }
    .back-to-operator {
      a {
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
        color: #695fe5;
        text-decoration: none;
      }
    }
  }

  @media screen and (min-width: 576px) {
    .select-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      select {
        flex: 0 0 75%;
        max-width: 75%;
      }
    }
  }
}

.editUserForm__wrap {
  padding-left: 9px;

  @media only screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .card-body {
    padding: 30px 50px 30px 15px;
    @media only screen and (max-width: 767px) {
      padding: 15px;
    }
    label {
      line-height: 27px;
      padding: 0 10px 0 0;
      text-align: right;
      width: 60px;
    }

    .edit-email-name {
      width: 60%;
      padding: 0;
      margin: 0;

      h6 {
        margin: 0;
        line-height: 27px;
      }

      .btn-switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 24px;

        .checkbox {
          position: absolute;
          opacity: 0;
          width: 0;
          height: 0;

          &:checked + .check-silder::before {
            border-color: #009900;
            transform: translateX(16px);
          }

          &:checked + .check-silder {
            background: #009900;
          }
        }

        .check-silder {
          position: absolute;
          cursor: pointer;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          background: #fff;
          border: 1px solid #dadada;
          transition: 0.4s;
          border-radius: 24px;

          &:before {
            position: absolute;
            content: '';
            height: 20px;
            width: 20px;
            left: 1px;
            bottom: 1px;
            top: 1px;
            background: #fff;
            border: 1px solid #dadada;
            transition: 0.4s;
            border-radius: 50%;
          }
        }
      }
    }

    .input,
    .form-group {
      margin-top: 20px;
      margin-bottom: 5px;
    }

    .error-msg {
      color: red;
      padding-bottom: 5px;
      display: inline-block;
    }

    .form-actions {
      margin-top: 1em;
      text-align: right;

      @media only screen and (max-width: 1024px) {
        padding-right: 0;
      }

      .btn-save--cancel {
        border: none;
        background: unset;
        color: #657187;
        // font-family: "SF UI Display";
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 35px;
        margin-right: 50px;

        &:hover,
        &:visited,
        &:focus {
          outline: none;
          box-shadow: none;
        }

        @media only screen and (max-width: 1024px) {
          margin-right: 20px;
        }
      }

      .btn-save--create {
        min-width: 146px;
        border-radius: 4px;
        background-color: #3b4b7e;
        outline: none !important;
      }
    }
  }
}
</style>
