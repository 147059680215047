<template>
  <CRow class>
    <CCol col="12" md="6">
      <EditUserFormComponent />
    </CCol>
  </CRow>
</template>

<script>
import { mapActions } from 'vuex';
import EditUserFormComponent from '@/components/UserAccount/EditUserFormComponent';
import Collapses from '@/views/base/Collapses';

export default {
  data() {
    return {
      user: null,
    };
  },
  name: 'EditUser',
  components: { EditUserFormComponent },
  methods: {
    ...mapActions(['getAction']),
  },
  mounted() {
    const userId = this.$route.params.userId;
    if (userId) {
      const isNumber = new RegExp('^[0-9]+$'); // check number
      if (isNumber.test(userId) && this.$store.state.user.userSelected.id == userId) {
        this.getAction('edit');
      }
    }
  },
  // async beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     const role = vm.$store.state.auth.role;

  //     if (!role) {
  //       vm.$router.push('/admin');
  //     } else {
  //       const userId = to.params.userId;
  //       if (!userId) {
  //         next();
  //       } else {
  //         const isNumber = new RegExp('^[0-9]+$'); // check number
  //         if (!isNumber.test(userId)) {
  //           next('/admin/user-management');
  //         } else {
  //           if (vm.$store.state.user.userSelected.id !== Number(userId)) {
  //             vm.$router.push('/admin/user-management');
  //           } else {
  //             return;
  //           }
  //         }
  //       }
  //     }
  //   });
  // },
  props: {
    userId: {
      type: String,
      default: undefined,
    },
  },
};
</script>

<style scoped>
</style>
